import React from 'react'
import '../../../src/App.css';
import CardsCoaching from '../CardsCoaching';

function Coaching() {
    return (
        <>
            <h1 className='coaching'>Coaching</h1>
            <CardsCoaching />
        </>
    )
}

export default Coaching