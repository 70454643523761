import React from 'react';
import '../css/Footer.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faCopyright } from '@fortawesome/free-solid-svg-icons'

function Footer() {
    return (
        <div className='footer-container'>
            <section className='footer-subscription'>
                <p className='footer-subscription-heading'>
                    Lets Work Together to Improve Your Professional Life!
                </p>
            </section>
            <div class='footer-links'>
                <div className='footer-link-wrapper'>
                    <div class='footer-link-items'>
                        <h2>Categories</h2>
                        <Link to='/'>Home</Link>
                        <Link to='/about'>About</Link>
                        <Link to='/coaching'>Coaching</Link>
                        <Link to='/blog'>Blog</Link>
                        <Link to='/books'>Books</Link>
                        <Link to='/contact'>Contact</Link>
                        <Link to='/login'>Log In</Link>
                    </div>
                </div>
                <div className='footer-link-wrapper'>
                    <div class='footer-link-items'>
                        <h2>Social Media</h2>
                        <Link to='/'>Instagram</Link>
                        <Link to='/'>Facebook</Link>
                        <Link to='/'>Youtube</Link>
                        <Link to='/'>Twitter</Link>
                    </div>
                </div>
            </div>
            <section className='footer-copyright'>
                <p className='footer-copyright-heading'>
                    <FontAwesomeIcon icon={faCopyright} /> 2022. Copyrights are Reserved.
                </p>
            </section>
        </div>
    )
}

export default Footer