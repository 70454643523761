import React from 'react'
import { Link } from 'react-router-dom';
import '../../src/css/Blogs.css';

function Blogs() {
    return (
        <>
            <div className='abouts'>
                <h6>&nbsp;</h6>
                <h1>Read the Latest!</h1>
                <div className='container'>
                    <div className='row'>
                        <div style={{ marginBottom: 20, marginTop: 50 }} className='col-md-4'>
                            <div className="card blogs__cards">
                                <img style={{ height: '275px' }} src="images/blog_img1.jpg" className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Some Tilte to the Blog</h5>
                                    <h5 className="card-user">POSTED 01-01-2022 | BY OLIVIA LAMBRAGNO</h5>
                                    <hr />
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <ul className='top_footers__items'>
                                        <Link className='blogs__button__link' to='/contact' >Continue Reading...</Link>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginBottom: 20, marginTop: 50 }} className='col-md-4'>
                            <div className="card blogs__cards">
                                <img style={{ height: '275px' }} src="images/blog_img2.jpg" className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Some Tilte to the Blog</h5>
                                    <h5 className="card-user">POSTED 01-01-2022 | BY OLIVIA LAMBRAGNO</h5>
                                    <hr />
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <ul className='top_footers__items'>
                                        <Link className='blogs__button__link' to='/contact' >Continue Reading...</Link>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginBottom: 20, marginTop: 50 }} className='col-md-4'>
                            <div className="card blogs__cards">
                                <img style={{ height: '275px' }} src="images/blog_img3.jpg" className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Some Tilte to the Blog</h5>
                                    <h5 className="card-user">POSTED 01-01-2022 | BY OLIVIA LAMBRAGNO</h5>
                                    <hr />
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <ul className='top_footers__items'>
                                        <Link className='blogs__button__link' to='/contact' >Continue Reading...</Link>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Blogs;