import React from 'react'
import '../../../src/App.css';
import Cards from '../Cards';
import IndexSection from '../IndexSection'
import Introductions from '../Introductions';
import TopFooter from '../TopFooter';

function Home() {
    return (
        <>
            <IndexSection />
            <Introductions />
            <Cards />
            <TopFooter />
        </>
    )
}

export default Home