import React from 'react';
import './App.css';
import Header from './components/Header';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './components/pages/Home';
import About from './components/pages/About';
import Coaching from './components/pages/Coaching';
import Blog from './components/pages/Blog';
import Book from './components/pages/Book';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/about' element={<About />}></Route>
          <Route path='/coaching' element={<Coaching />}></Route>
          <Route path='/blog' element={<Blog />}></Route>
          <Route path='/book' element={<Book />}></Route>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
