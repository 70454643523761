import React from 'react';
import '../../src/App.css';
import { Button } from "../components/Button";
import '../../src/css/IndexSection.css';

function IndexSection() {
    return (
        <div className='index-container'>
            <h6>Professional Life Coach & Bussiness</h6>
            <h1>Helping You Achieve Anything</h1>
            <div className='index-btns'>
                {/*<Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large'>
                    FREE CONSULTATION
                </Button>*/}
            </div>
        </div>
    )
}

export default IndexSection;