import React from 'react'
import '../../src/css/Books.css';
import IndexIntroduction from './IndexIntroduction';

function Books() {
    return (
        <>
            <div className='cards'>
                <h6>Bestelling Books</h6>
                <h1>Coaching Books</h1>
                <div className='container'>
                    <div className='row'>
                        <div style={{ marginBottom: 20, marginTop: 50 }} className='col-md-6'>
                            <h6>Shot Introduction about the Book!</h6>
                            <h1>Book Name 1</h1>
                            <ul className='introductions__items'>
                                <IndexIntroduction
                                    text="Some Text Here... Some Text Here... Some Text Here... Some Text Here...
                                  Some Text Here... Some Text Here... Some Text Here... Some Text Here...
                                  Some Text Here... Some Text Here... Some Text Here... Some Text Here... " />
                            </ul>
                        </div>
                        <div style={{ marginBottom: 20, marginTop: 50 }} className='col-md-6 text-center'>
                            <img className='img-fluid books__img' src="images/book_img1.jpg" alt="Home Introduction Image" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='abouts'>
                <div className='container'>
                    <div className='row'>
                        <div style={{ marginBottom: 20, marginTop: 50 }} className='col-md-6 text-center'>
                            <img className='img-fluid books__img' src="images/book_img2.jpg" alt="Home Introduction Image" />
                        </div>
                        <div style={{ marginBottom: 20, marginTop: 50 }} className='col-md-6'>
                            <h6>Shot Introduction about the Book!</h6>
                            <h1>Book Name 2</h1>
                            <ul className='introductions__items'>
                                <IndexIntroduction
                                    text="Some Text Here... Some Text Here... Some Text Here... Some Text Here...
                                  Some Text Here... Some Text Here... Some Text Here... Some Text Here...
                                  Some Text Here... Some Text Here... Some Text Here... Some Text Here... " />
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Books;