import React from 'react'
import { Link } from 'react-router-dom';
import '../../src/css/TopFooter.css';
import IndexTopFooter from './IndexTopFooter';

function TopFooter() {
    return (
        <div className='top_footers'>
            <div className='top_footers__container'>
                <div className='top_footers__wrapper'>
                    <ul className='top_footers__items'>
                        <IndexTopFooter
                            text="Find Workable Solutions which will Succeed within the Structure" />
                    </ul>
                </div>
            </div>
            <br />
            <div className='top_footers__container'>
                <div className='top_footers__wrapper'>
                    <ul className='top_footers__items'>
                        <Link className='top_footers__button__link' to='/contact' >Free Consultation</Link>
                    </ul>
                    
                </div>
            </div>
        </div>
    )
}

export default TopFooter;