import React from 'react'
import '../../src/css/CardsCoaching.css';
import IndexCard from './IndexCardCoaching'

function Cards() {
    return (
        <div className='cards'>
            <h6>&nbsp;</h6>
            <h1>Our Specialties</h1>
            <div className='container'>
                <div className='row'>
                    <div style={{ marginBottom: 20 }} className='col-md-6'>
                        <div className='cards__wrapper'>
                            <ul className='cards__items'>
                                <IndexCard
                                    src='images/card_1.jpg'
                                    label='Coaching Personal'
                                    path='../coaching' />
                            </ul>
                        </div>
                    </div>
                    <div style={{ marginBottom: 20 }} className='col-md-6'>
                        <div className='cards__wrapper'>
                            <div style={{ textAlign: 'justify', fontSize: 18 }} className='card__item__text'>
                                La vida está llena de sueños. Los sueños, sin embargo, no tienen sentido, si no
                                sabemos qué queremos soñar, porque somos nosotros quienes creamos nuestros
                                propios sueños.
                            </div>
                            <br />
                            <div style={{ textAlign: 'justify', fontSize: 18 }} className='card__item__text'>
                                El objetivo de este programa es identificar dónde estoy ahora y a dónde quiero llegar,
                                olvidándonos del pasado y enfocándonos en el futuro.
                            </div>
                            <br />
                            <div style={{ textAlign: 'justify', fontSize: 18 }} className='card__item__text'>
                                El coaching no trabaja con lo que te paso hoy, trabaja con lo que te va a pasar mañana.
                                Descubre cuales son tus verdaderas metas y trabajemos juntas en alcanzarlas.
                            </div>
                            <br />
                            <div style={{ textAlign: 'justify', fontSize: 18 }} className='card__item__text'>
                                ¡Regístrate para una cita!
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div style={{ marginBottom: 20 }} className='col-md-6'>
                        <div className='cards__wrapper'>
                            <ul className='cards__items'>
                                <IndexCard
                                    src='images/card_2.jpg'
                                    label='Coaching Parejas'
                                    path='../coaching' />
                            </ul>
                        </div>
                    </div>
                    <div style={{ marginBottom: 20 }} className='col-md-6'>
                        <div className='cards__wrapper'>
                            <div style={{ textAlign: 'justify', fontSize: 18 }} className='card__item__text'>
                                Sabemos que el convivir con alguien totalmente diferente a nosotros, no es algo fácil,
                                pero nadie dice que es imposible. Las relaciones llenas de comunicación, compromiso,
                                honestidad, respeto y sobre todo amor, son si lugar a duda relaciones duraderas.
                            </div>
                            <br />
                            <div style={{ textAlign: 'justify', fontSize: 18 }} className='card__item__text'>
                                Aunque tu relación parezca difícil y creas que no tenga una salida, todo tiene una solución.
                                Te invito a que participes en nuestras sesiones de pareja y encontremos juntos la solución.
                            </div>
                            <br />
                            <div style={{ textAlign: 'justify', fontSize: 18 }} className='card__item__text'>
                                ¡Regístrate para una cita!
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div style={{ marginBottom: 20 }} className='col-md-6'>
                        <div className='cards__wrapper'>
                            <ul className='cards__items'>
                                <IndexCard
                                    src='images/card_3.jpg'
                                    label='Coaching Negocios'
                                    path='../coaching' />
                            </ul>
                        </div>
                    </div>
                    <div style={{ marginBottom: 20 }} className='col-md-6'>
                        <div className='cards__wrapper'>
                            <div style={{ textAlign: 'justify', fontSize: 18 }} className='card__item__text'>
                                Si la repetición de una actividad o esfuerzo, no nos da el resultado que queremos, la
                                solución es muy simple, debemos cambiar la estrategia.
                            </div>
                            <br />
                            <div style={{ textAlign: 'justify', fontSize: 18 }} className='card__item__text'>
                                Transforma tu negocio o tu sueño de negocio, donde lo tienes ahora, hacia donde lo
                                quieres llevar. Empieza a visualizar y, sobre todo, a poner en marcha un plan de acción
                                que te permita alcanzar el éxito de tu visión de negocio. Descubre como puedes lograrlo
                                a través de nuestras sesiones.
                            </div>
                            <br />
                            <div style={{ textAlign: 'justify', fontSize: 18 }} className='card__item__text'>
                                ¡Regístrate para una cita!
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div style={{ marginBottom: 20 }} className='col-md-6'>
                        <div className='cards__wrapper'>
                            <ul className='cards__items'>
                                <IndexCard
                                    src='images/card_1.jpg'
                                    label='Coaching Real Estate'
                                    path='../coaching' />
                            </ul>
                        </div>
                    </div>
                    <div style={{ marginBottom: 20 }} className='col-md-6'>
                        <div className='cards__wrapper'>
                            <div style={{ textAlign: 'justify', fontSize: 18 }} className='card__item__text'>
                                Si estás empezando tu carrera de Real Estate y llevas algún tiempo sin ver los
                                resultados deseados, te invito a que participes de nuestro Coaching de R.S. y lleves tu
                                negocio al nivel que deseas. Desde una excelente presentación hasta un open house,
                                pasando por identidad de imagen y estrategias de mercadeo. Crea tu propia y autentica
                                forma de triunfar en este negocio.
                            </div>
                            <br />
                            <div style={{ textAlign: 'justify', fontSize: 18 }} className='card__item__text'>
                                ¡Regístrate para una cita!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cards;