import React from 'react';

function IndexTopFooter(props) {
    return (
        <>
            <li className='top_footers__items'>
                <div className='top_footers__item__link'>
                    <div className='top_footers__item__info'>
                        <h5 className='top_footers__item__text'>{props.text}</h5>
                    </div>
                </div>
            </li>
        </>
    )
}

export default IndexTopFooter