import React from 'react';

function IndexAbout(props) {
    return (
        <>
            <li className='abouts__items'>
                <div className='abouts__item__link'>
                    <div className='abouts__item__info'>
                        <h5 className='abouts__item__text'>{props.text}</h5>
                    </div>
                </div>
            </li>
        </>
    )
}

export default IndexAbout