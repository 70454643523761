import React from 'react';
import { Link } from 'react-router-dom';

function IndexCards(props) {
    return (
        <>
            <li className='cards__item'>
                <Link className='cards__item__link' to={props.path}>
                    <figure style={{marginBottom: 0}} className='cards__item__pic-wrap' data-category={props.label}>
                        <img className='cards__item__img' src={props.src} alt="Item 1" />
                    </figure>
                </Link>
            </li>
        </>
    )
}

export default IndexCards;