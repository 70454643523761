import React from 'react'
import '../../../src/App.css';
import Books from '../Books';

function Book() {
    return (
        <>
            <h1 className='book'>Books</h1>
            <Books />
        </>
    )
}

export default Book