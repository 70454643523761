import React from 'react'
import '../../src/css/Introductions.css';
import IndexIntroduction from './IndexIntroduction'

function Introductions() {
    return (
        <div className='introductions'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6'>
                        <h6>It's Time for a Change in your Life</h6>
                        <h1>Our Introduction</h1>
                        <ul className='introductions__items'>
                            <IndexIntroduction
                                text="Todos sabemos la importancia de tener un coach, la idea es saber exactamente para qué
                                necesito este coaching, puede ser para negocios, para parejas, para tu vida." />
                        </ul>
                        <ul className='introductions__items'>
                            <IndexIntroduction
                                text="Mi propósito es llevarte de donde estás ahora, a donde tú quieres llegar, navegando a través de
                                tus situaciones tal vez difíciles, sueños no realizados, deseos frustrados, problemas en tus
                                relaciones e incapacidad de desarrollo personal y profesional." />
                        </ul>
                        <ul className='introductions__items'>
                            <IndexIntroduction
                                text="Entendiendo por coaching, el proceso de visualizar necesidades, proyectar soluciones y aplicar
                                capacidades y conocimientos al desarrollo de desafío y logro de oportunidades." />
                        </ul>
                    </div>
                    <div className='col-md-6'>
                        <img className='img-fluid' src="images/home_introduction.jpg" alt="Home Introduction Image" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Introductions;