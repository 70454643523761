import React from 'react'
import '../../../src/App.css';
import Abouts from '../Abouts';

function About() {
    return (
        <>
            <h1 className='about'>About</h1>
            <Abouts />
        </>
    )
}

export default About