import React from 'react'
import '../../../src/App.css';
import Blogs from '../Blogs';

function Blog() {
    return (
        <>
            <h1 className='blog'>Blog</h1>
            <Blogs />
        </>
    )
}

export default Blog