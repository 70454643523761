import React from 'react'
import '../../src/css/Abouts.css';
import '../../src/css/Cards.css';
import IndexAbout from './IndexAbout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

function Abouts() {
    return (
        <>
            <div className='abouts'>
                <h6>About Olivia Lambragno</h6>
                <h1>Meet Olivia - A Modern-Day Life Coach & Advisor!</h1>
                <br />
                <div className='container'>
                    <div className='row'>
                        <div style={{ marginBottom: 20 }} className='col-md-6'>
                            <img className='img-fluid' src="images/about_1.jpg" alt="About 1 Image" />
                        </div>
                        <div className='col-md-6'>
                            <div className='abouts__container'>
                                <div className='abouts__wrapper'>
                                    <ul style={{ marginBottom: 0 }} className='abouts__items'>
                                        <IndexAbout
                                            text="¡Hola, Soy Olivia!" />
                                    </ul>
                                </div>
                            </div>
                            <div className='abouts__container'>
                                <div className='abouts__wrapper'>
                                    <ul style={{ marginBottom: 0 }} className='abouts__items'>
                                        <IndexAbout
                                            text="Nací y crecí en Barranquilla, Colombia. Soy Comunicadora Social, Escritora, Coach, Tengo un grado en
                                Ventas y Mercadeo, Psicología Social y Poseo una licencia de Real Estate. Actualmente tomo clases
                                para convertirme en una motivadora personal y de negocios." />
                                    </ul>
                                </div>
                            </div>
                            <div className='abouts__container'>
                                <div className='abouts__wrapper'>
                                    <ul style={{ marginBottom: 0 }} className='abouts__items'>
                                        <IndexAbout
                                            text="Soy una persona optimista y llena de energía. Estoy aquí para ayudarte a mover de donde estas ahora
                                hacia donde quieres estar." />
                                    </ul>
                                </div>
                            </div>
                            <div className='abouts__container'>
                                <div className='abouts__wrapper'>
                                    <ul style={{ marginBottom: 0 }} className='abouts__items'>
                                        <IndexAbout
                                            text="En una ocasión decidí que quería venir a US para descubrir las oportunidades que este hermoso país
                                ofrece. Creo firmemente que fue la mejor decisión. Aquí aprendí lo que siempre digo, La vida está llena
                                de oportunidades y depende de nosotros el tomarlas o dejarlas pasar." />
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className='container'>
                    <div className='row'>
                        <div style={{ marginBottom: 20 }} className='col-md-6'>

                            <div className='abouts__container'>
                                <div className='abouts__wrapper'>
                                    <ul style={{ marginBottom: 0 }} className='abouts__items'>
                                        <IndexAbout
                                            text="A pesar de venir siendo adulta, aquí crecí profesionalmente. He trabajado durante mucho tiempo con la
                                comunidad latina, especialmente las mujeres, por quienes siento un gran respeto. Considero que somos
                                capaces de lograr cualquier cosa que logremos imaginar." />
                                    </ul>
                                </div>
                            </div>
                            <div className='abouts__container'>
                                <div className='abouts__wrapper'>
                                    <ul style={{ marginBottom: 0 }} className='abouts__items'>
                                        <IndexAbout
                                            text="Me gusta aprender cosas nuevas, por eso paso mucho tiempo leyendo, tratando de encontrar nuevas
                                formas de ayudar a otras mujeres a lograr sus sueños." />
                                    </ul>
                                </div>
                            </div>
                            <div className='abouts__container'>
                                <div className='abouts__wrapper'>
                                    <ul style={{ marginBottom: 0 }} className='abouts__items'>
                                        <IndexAbout
                                            text="Te invito a que me contactes para que juntas descubramos que es lo que se está interponiendo que no
                                te permite vivir la vida que mereces, cuáles son esos pensamientos negativos que te imposibilitan lograr
                                tus sueños, que comentarios u opiniones negativas te impiden tomar acción y ser o llegar donde
                                deseas." />
                                    </ul>
                                </div>
                            </div>
                            <div className='abouts__container'>
                                <div className='abouts__wrapper'>
                                    <ul style={{ marginBottom: 0 }} className='abouts__items'>
                                        <IndexAbout
                                            text="Eres un ser único y especial y mereces lo mejor del mundo, nunca lo olvides." />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <img className='img-fluid' src="images/about_2.jpg" alt="About 2 Image" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='cards'>
                <h6>Benefits of the Coach Yourself to Success</h6>
                <h1>My Experience</h1>
                <div style={{ marginTop: 50 }} className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <ul className='abouts__experience'>
                                <li><FontAwesomeIcon style={{ paddingRight: 20, color: "#BA233B" }} icon={faCheck} />Improve personal and professional relationships.</li>
                                <li><FontAwesomeIcon style={{ paddingRight: 20, color: "#BA233B" }} icon={faCheck} />Get on the path to financial independence.</li>
                                <li><FontAwesomeIcon style={{ paddingRight: 20, color: "#BA233B" }} icon={faCheck} />Work less while making more money.</li>
                                <li><FontAwesomeIcon style={{ paddingRight: 20, color: "#BA233B" }} icon={faCheck} />Stop struggling and start enjoying life.</li>
                                <li><FontAwesomeIcon style={{ paddingRight: 20, color: "#BA233B" }} icon={faCheck} />Have more time to enjoy life and family.</li>
                                <li><FontAwesomeIcon style={{ paddingRight: 20, color: "#BA233B" }} icon={faCheck} />Find the love of your life (if you haven’t already).</li>
                            </ul>
                        </div>
                        <div className='col-md-6'>
                            2
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Abouts;