import React from 'react';

function IndexIntroduction(props) {
    return (
        <>
            <li className='introductions__items'>
                <div className='introductions__item__link'>
                    <div className='introductions__item__info'>
                        <h5 className='introductions__item__text'>{props.text}</h5>
                    </div>
                </div>
            </li>
        </>
    )
}

export default IndexIntroduction