import React from 'react'
import '../../src/css/Cards.css';
import IndexCard from './IndexCard'

function Cards() {
    return (
        <div className='cards'>
            <h6>&nbsp;</h6>
            <h1>Our Specialties</h1>
            <div className='cards__container'>
                <div className='cards__wrapper'>
                    <ul className='cards__items'>
                        <IndexCard
                            src='images/card_1.jpg'
                            text='La vida está llena de sueños...'
                            label='Coaching Personal'
                            path='../coaching' />
                        <IndexCard
                            src='images/card_2.jpg'
                            text='Sabemos que el convivir con alguien...'
                            label='Coaching Parejas'
                            path='../coaching' />
                        <IndexCard
                            src='images/card_3.jpg'
                            text='Si la repetición de una actividad...'
                            label='Coaching Negocios'
                            path='../coaching' />
                        <IndexCard
                            src='images/card_1.jpg'
                            text='Si estás empezando tu carrera de...'
                            label='Coaching Real Estate'
                            path='../coaching' />
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Cards;